/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import { useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { LayerMaterial, Base, Depth, Fresnel, Noise } from 'lamina/vanilla';

const colorA = new THREE.Color('#2032A5').convertSRGBToLinear();
const colorB = new THREE.Color('#0c575f').convertSRGBToLinear();
const fresnel = new THREE.Color('#FFA0E0').convertSRGBToLinear();
const material = new LayerMaterial({
  layers: [
    new Base({ color: colorA }),
    new Depth({
      colorA: colorA,
      colorB: colorB,
      alpha: 0.5,
      mode: 'normal',
      near: 0,
      far: 2,
      origin: [1, 1, 1],
    }),
    new Depth({
      colorA: '#FFA0E0',
      colorB: colorB,
      alpha: 0.5,
      mode: 'add',
      near: 3,
      far: 2,
      origin: [1, 1, 1],
    }),
    new Fresnel({
      mode: 'add',
      color: fresnel,
      intensity: 0.3,
      power: 2.5,
      bias: 0.0,
    }),
  ],
});

export function Noodle() {
  const { nodes } = useGLTF('/noodle-transformed.glb');
  const [geometry] = useState(() => nodes.Path.geometry);
  return (
    <mesh
      position={[10, 3, 8]}
      castShadow
      receiveShadow
      scale={0.03}
      geometry={geometry}
      material={material}
    />
  );
}

useGLTF.preload('/noodle-transformed.glb');

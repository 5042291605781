/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.3 Waylo.gltf --transform
*/
import * as THREE from 'three'
import React, {useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { Noodle } from './Noodle'


export function Model(props) {
  const { nodes, materials } = useGLTF('/Waylo-transformed.glb')
  const ref = useRef()
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.rotation.x = -Math.PI / 1.75 + Math.cos(t / 4) / 8
    ref.current.rotation.y = Math.sin(t / 4) / 8
    ref.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20
    ref.current.position.y = (1 + Math.sin(t / 1.5)) / 10
  })
  return (
    <group ref={ref} {...props} dispose={null}>
      <Noodle />
      <group  scale={0.01}>
        <mesh castShadow receiveShadow geometry={nodes.Path.geometry} material={materials['Chrome Glossy 5']}  position={[0, 0, 353.9]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh castShadow receiveShadow geometry={nodes.Path_1.geometry} material={materials['Chrome Glossy 5']}  position={[0, -1, 353.9]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh castShadow receiveShadow geometry={nodes.Path_2.geometry}material={materials['Chrome Glossy 5']}  position={[0, -2, 353.9]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh castShadow receiveShadow geometry={nodes.Path_3.geometry} material={materials['Chrome Glossy 5']} position={[0, -3, 353.9]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh castShadow receiveShadow geometry={nodes.Circle.geometry} material={materials['Chrome Glossy 5']} position={[0, -4, 353.9]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh castShadow receiveShadow geometry={nodes.Path_4.geometry} material={materials['Chrome Glossy 5']}  position={[0, -5, 353.9]} rotation={[Math.PI / 2, 0, 0]} />
      </group>
    </group>

  )
}

useGLTF.preload('/Waylo-transformed.glb')
